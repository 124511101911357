import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import "./sgcarousel.css";
import SpecialNewsCard from "./SpecialNewsCard";
import SgBottomCarousel from "./SgBottomCarousel";
import SgBottomCarouselMobile from "./SgBottomCarouselMobile";
import LiveStream from "../liveStream/LiveStream";

const API_BASE_URL = "https://api.sigortagundem.com";

const SgCarousel = () => {
  const [topCarouselNews, setTopCarouselNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchNews = useCallback(async () => {
    try {
      const [regularResponse, pinnedResponse] = await Promise.all([
        axios.get(API_BASE_URL),
        axios.get(`${API_BASE_URL}/sabitlenmis`)
      ]);

      if (Array.isArray(regularResponse.data.news) && Array.isArray(pinnedResponse.data)) {
        const pinnedNews = pinnedResponse.data;
        
        // Filter out regular news that are not pinned
        const regularNews = regularResponse.data.news.filter(
          (news) => 
            news.isFullWidthHeadline && 
            !news.isDraft &&
            !pinnedNews.some(pinnedNews => pinnedNews._id === news._id)
        );

        // Create an array of 10 slots
        const combinedNews = Array(10).fill(null);

        // Place pinned news in their specific positions
        pinnedNews.forEach((news) => {
          if (news.sabitlenmisSlidePosition !== null && news.sabitlenmisSlidePosition >= 1 && news.sabitlenmisSlidePosition <= 10) {
            combinedNews[news.sabitlenmisSlidePosition - 1] = news;
          }
        });

        // Fill the remaining slots with regular news
        let regularNewsIndex = 0;
        for (let i = 0; i < 10; i++) {
          if (combinedNews[i] === null && regularNewsIndex < regularNews.length) {
            combinedNews[i] = regularNews[regularNewsIndex];
            regularNewsIndex++;
          }
        }

        // Remove any remaining null entries
        setTopCarouselNews(combinedNews.filter((news) => news !== null));
      } else {
        console.error("API'den beklenen veri formatı bir dizi değil.");
      }
    } catch (error) {
      console.error("Veriler alınırken bir hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % topCarouselNews.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [topCarouselNews.length]);

  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + topCarouselNews.length) % topCarouselNews.length
    );
  }, [topCarouselNews.length]);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % topCarouselNews.length);
  }, [topCarouselNews.length]);

  const handleNewsClick = useCallback(async (newsId, slug, event) => {
    event.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      window.location.href = `/${slug}`; 
    } catch (error) {
      console.error("Error incrementing view count:", error);
      window.location.href = `/${slug}`; 
    }
  }, []);
  

  const getNewsTag = useCallback((slide) => {
    if (slide.isFlashNewsTag) return "Flaş Haber";
    if (slide.isBreakingNewsTag) return "Son Dakika";
    if (slide.isExclusiveNewsTag) return "Özel Haber";
    if (slide.isAttentionNewsTag) return "Bu Habere Dikkat";
    return null;
  }, []);

  const carouselItems = useMemo(() => {
    return topCarouselNews.map((slide, index) => (
      <div
        key={slide._id}
        onClick={(e) => handleNewsClick(slide._id, slide.slug, e)}
        className={`sg-top-carousel-item cursor-pointer ${
          index === currentIndex ? "active" : ""
        }`}
        style={{
          backgroundImage: `url(${slide.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          flexShrink: "0",
          width: "100%",
          position: "relative",
        }}
      >
        <div className="sg-top-carousel-caption">
          <h5>{slide.title}</h5>
        </div>
        {getNewsTag(slide) && (
          <div
            className="slide-title"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "red",
              color: "white",
              padding: "5px 10px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {getNewsTag(slide)}
          </div>
        )}
      </div>
    ));
  }, [topCarouselNews, currentIndex, handleNewsClick, getNewsTag]);

  if (loading) {
    // return <div>Yükleniyor...</div>;
  }

  if (!topCarouselNews.length) {
    // return <div>Haber bulunamadı.</div>;
  }

  return (
    <div className="sg-carousel-container lg:mx-[550px]">
      <LiveStream />
      <div className="sg-carousel-top-container">
        <SgBottomCarousel />
      </div>
      <div className="hidden sg-carousel-top-mobile-container">
        <SgBottomCarouselMobile />
      </div>
      <div className="sg-carousel-bottom-container lg:grid grid-cols-3 gap-4 mt-4">
        <div className="sg-carousel-bottom-left-container col-span-2 relative overflow-hidden">
          <div
            className="sg-carousel-inner"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              display: "flex",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {carouselItems}
          </div>
          
          <button className="carousel-control-prev" onClick={goToPrevious}>
          <span class="material-symbols-outlined">arrow_back_ios
</span>
          </button>
          <button className="carousel-control-next" onClick={goToNext}>
          <span class="material-symbols-outlined">arrow_forward_ios
</span>
          </button>
        </div>
        <div className="sg-carousel-bottom-right-container">
          <SpecialNewsCard />
        </div>
      </div>
    </div>
  );
};

export default SgCarousel;