import { useState, useEffect } from "react";
import React from "react";
import "./livestream.css";

const LiveStream = () => {
  const [livestreamLink, setLivestreamLink] = useState("");

  useEffect(() => {
    const fetchLivestreamLink = async () => {
      try {
        const response = await fetch(
          `https://api.sigortagundem.com/api/livestreams`
        );
        if (!response.ok) {
          throw new Error("Veri alınamadı.");
        }
        const data = await response.json();
        if (data.length > 0) {
          setLivestreamLink(data[0].link);
        } else {
          console.error("Canlı yayın verisi bulunamadı.");
        }
      } catch (error) {
        console.error(
          "Canlı yayın verisi alınırken bir hata oluştu:",
          error.message
        );
      }
    };

    fetchLivestreamLink();
  }, []);

  

  return (
    <div className={`live-stream-container ${livestreamLink ? '' : 'hidden'}`}>
      <iframe className="live-stream-link"
        width="100%"
        height="721"
        src={livestreamLink}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default LiveStream;
