import "./App.css";
import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Kunye from "./pages/kunye/KunyePage";
import AnimatedText from "../src/components/animatedText/AnimatedText";
// import Carousel from "./components/carousel/carousel";
import AdsLeft from "../src/components/ads/AdsLeft";
import AdsRight from "../src/components/ads/AdsRights";
import Footer from "./components/footer/Footer";
import TopButton from "./components/topButton/TopButton";
import ContentPage from "./pages/content/ContentPage";
import ContactPage from "./pages/contact/ContactPage";
import About from "./pages/about/About";
import PuanDurumu from "./components/puanDurumu/puanDurumu";
import ProfilePage from "./pages/profile/ProfilePage";
import PrayerTime from "./components/namaz/PrayerTime";
import Altin from "./components/altin/altin";
import Kur from "./components/kurDegisim/kur";
import TrafficInfo from "./components/traffic/TrafficInfo";
import PharmacyPage from "./components/pharmacy/PharmacyPage";
import InsuranceCategory from "./pages/category/InsuranceCategory";
import DailyCategory from "./pages/category/DailyCategory";
import TrafficCategory from "./pages/category/TrafficCategory";
import BackstageCategory from "./pages/category/BackstageCategory";
import FinanceCategory from "./pages/category/FinanceCategory";
import WorldCategory from "./pages/category/WorldCategory";
import Kripto from "./components/kripto/kripto";
import Hisse from "./components/hisse/hisse";
import SearchPage from "./pages/search/SearchPage";
import LiveTv from "./components/liveTv/LiveTv";
import Parite from "./components/parite/parite";
import Weather from "./components/weatherPage/weather";
import AdsMobile from "./components/ads/AdsMobile";
import AdsTablet from "./components/ads/adsTablet.jsx";
import AdsDownPopup from "./components/ads/adsDownPopup";
import ElementerCategory from "./pages/category/ElementerCategory";
import BesCategory from "./pages/category/BesCategory";
import AlbumCategory from "./pages/category/AlbumCategory";
import AlbumContentPage1 from "./pages/photoAlbum/AlbumContentPage1";
import AlbumContentPage2 from "./pages/photoAlbum/AlbumContentPage2";
import AlbumContentPage3 from "./pages/photoAlbum/AlbumContentPage3";
import AlbumContentPage4 from "./pages/photoAlbum/AlbumContentPage4";
import AlbumContentPage5 from "./pages/photoAlbum/AlbumContentPage5";
import PrivacyPolicy from "./pages/policy/PrivacyPolicy.jsx";
import ClarificationText from "./pages/policy/ClarificationText.jsx";
import ApprovedPage from "./pages/approval/ApprovedPage.jsx";
import DeclinedPage from "./pages/approval/DeclinedPage.jsx";
import AlbumContentPage6 from "./pages/photoAlbum/AlbumContentPage6.jsx";
import TagsPage from "./pages/category/TagsPage.jsx";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <AnimatedText />
        <Navbar />
        <AdsMobile />
        <AdsTablet/>
        <AdsLeft />
        <AdsRight />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:slug" element={<ContentPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<About />} />

          <Route path="/sigorta" element={<InsuranceCategory />} />
          <Route path="/dunya" element={<WorldCategory />} />
          <Route path="/kulis" element={<BackstageCategory />} />
          <Route path="/kasko" element={<TrafficCategory />} />
          <Route path="/gundem" element={<DailyCategory />} />
          <Route path="/finans" element={<FinanceCategory />} />
          <Route path="/elementer" element={<ElementerCategory />} />
          <Route path="/bes" element={<BesCategory />} />

          <Route path="/foto-galeri" element={<AlbumCategory />} /> 
          <Route path="/photo-album-1" element={<AlbumContentPage1 />} />
          <Route path="/photo-album-2" element={<AlbumContentPage2 />} /> 
          <Route path="/photo-album-3" element={<AlbumContentPage3 />} /> 
          <Route path="/photo-album-4" element={<AlbumContentPage4 />} />
          <Route path="/photo-album-5" element={<AlbumContentPage5 />} />
          <Route path="/photo-album-6" element={<AlbumContentPage6 />} />

          <Route path="/Kunye" element={<Kunye />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/parite" element={<Parite />} />
          <Route path="/gizlilik-politikasi" element={<PrivacyPolicy />} />
          <Route path="/aydinlatma-metni" element={<ClarificationText />} />
          <Route path="/onayliyorum" element={<ApprovedPage />} />
          <Route path="/onaylamiyorum" element={<DeclinedPage />} />
          <Route path="/tags/:tag" element={<TagsPage />} />
        </Routes>
        {/* <AdsDownPopup /> */}
        <TopButton />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
