import React from 'react';
import "./policyPage.css"
import TitleBar from '../../components/titleBar/TitleBar';

const PrivacyPolicy = () => {
    return (
        <div className='policy-page-container'>

            <div className="text-center bg-[#374140] p-2 mb-4 shadow-md text-white ">
                <h1 className="text-2xl font-bold " >Gizlilik Politikası</h1>
            </div>

            <div class="policy-page-header">
                ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş. 
            </div>

            <div class="policy-page-section">
                <h2>Önsöz</h2>
                <p>Kişisel Verilerin Korunması Kanunu kapsamında ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş. olarak kişisel verilerinizin ve/veya özel nitelikli kişisel verilerinizin korunmasına önem vermekteyiz. Şirketimize muhtelif yollardan iletilen sizlere ait tüm kişisel verilerin ve/veya özel nitelikli kişisel verilerin saklanması hususunda hassasiyet göstermekteyiz. Bu bağlamda 6698 Sayılı Kişisel Verilerin Korunması Kanunu başta olmak üzere T.C. Anayasası ve sair mevzuat hükümlerine uyum sağlayabilmek adına ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş. olarak gereken teknik ve idari tedbirleri almış bulunmaktayız. Kanunlar tarafından güvence altına alınan haklarınızı koruyacağımızı da önemle belirtmek isteriz. Bu bağlamda kişisel verilerinizi ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş.. ile güvenle paylaşabilir, öneri, şikâyet ve tereddütlerinizi tarafımıza iletebilirsiniz.</p>
                <p>Kişisel verilerinizin korunması konusunda bilhassa önem arz eden ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş. bünyesinde yürürlüğe konulmuş olan Gizlilik Politikamızı sizlerle paylaşmaktayız.</p>
            </div>

            <div class="policy-page-section">
                <h2>1. Tanımlar</h2>
                <p><span class="policy-page-definition">Veri Sorumlusu:</span> Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş.’yi ifade etmektedir.</p>
                <p><span class="policy-page-definition">Veri Sahibi-İlgili Kişi:</span> Kişisel verisi işlenen gerçek kişiyi ifade etmektedir.</p>
                <p><span class="policy-page-definition">Şirket:</span> İşbu politika uyarınca ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş.’yi ifade etmektedir.</p>
                <p><span class="policy-page-definition">Kanun:</span> “6698 Sayılı Kişisel Verilerin Korunması Kanunu” ifade edilmektedir.</p>
                <p><span class="policy-page-definition">Kişisel Veri:</span> Kimliği belirli veya belirlenebilir kılan gerçek kişiye ilişkin her türlü bilgi ifade edilmektedir.</p>
                <p><span class="policy-page-definition">Özel Nitelikli Kişisel Veri:</span> Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri özel nitelikli kişisel verilerini ifade etmektedir.</p>

                <p><span class="policy-page-definition"> Kurul:</span>  Kişisel Verileri Koruma Kurulunu ifade etmektedir. </p>

                <p><span class="policy-page-definition"> Politika:</span>  İşbu “Gizlilik Politikası” ifade edilmektedir. </p>

                <p><span class="policy-page-definition"> Kişisel Verilerin İşlenmesi:</span>  Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem ifade edilmektedir. </p>

                <p><span class="policy-page-definition"> Açık Rıza:</span>  Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza ifade edilmektedir. </p>

                <p><span class="policy-page-definition"> Silme:</span>   Kişisel verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesini ifade etmektedir.</p>

                <p><span class="policy-page-definition"> Anonim Hale Getirme:</span>   Kişisel verilerin başka verilerle eşleştirilse dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesini ifade etmektedir.</p>

                <p><span class="policy-page-definition"> İmha:</span>  Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesini ifade etmektedir. </p>

                <p><span class="policy-page-definition"> Veri:</span>  Kişisel veriler ve özel nitelikli kişisel veriler birlikte ifade edilmektedir. </p>

            </div>

            <div class="policy-page-section">
                <h2>2. Gizlilik Politikasının Amacı</h2>
                <p>Kişisel verilerin korunması ve kanuna uyum sağlanması temel prensibimizdir. ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş. (Bundan sonra “Erişim Medya” veya “Şirket” olarak anılacaktır.) olarak yaptığımız tüm işlerde sizlerden almış olduğumuz kişisel veriler ve/veya özel nitelikli kişisel veriler gizli tutulmuş ve üçüncü kişilerle paylaşılmamıştır. Bu bağlamda şirketimiz daima kişisel verilerin ve/veya özel nitelikli kişisel verilerin korunmasına ilişkin gereken hassasiyeti göstermiştir. Yine 6698 Sayılı Kişisel Verilerin Korunması Kanunu (Bundan sonra “Kanun” ya da “KVKK” olarak anılacaktır.) uyarınca şirket içi düzenlemelerimiz yeniden yapılmış olup; teknik ve idari tedbirler alınmıştır. Devam eden süreçte de Erişim Medya olarak kanunun getirdiği tüm yükümlülüklere uymayı kabul, beyan ve taahhüt etmekteyiz.</p>
            </div>

            <div class="policy-page-section">
                <h2>3. Gizlilik Politikasının Kapsamı</h2>
                <p>İşbu Gizlilik Politikamız 6698 Sayılı Kişisel Verilerin Korunması Kanununa uygun olarak hazırlanmıştır.</p>
                <p>Kişisel verileriniz ve/veya özel nitelikli kişisel verileriniz rızanızla ya da kanuna uygunluk halleri kapsamında işlenmektedir. Söz konusu verileriniz;</p>
                <ul>
                    <li> Şirket güvenliğinin sağlanabilmesi,</li>
                    <li>Sizlere eksiksiz hizmet sunulabilmesi,</li>
                    <li>Ticari faaliyetlerimizin yürütülebilmesi,</li>
                    <li>Sorunlarınızın hızlıca çözümlenebilmesi,</li>
                    <li>Kalitemizin artırılabilmesi, amacıyla kullanılmaktadır.</li>
                </ul>

                <p>Sizlerden gelen bazı kişisel veriler ve/veya özel nitelikli kişisel veriler Kanunun öngördüğü usullerde kişisellikten çıkarılmakta ve anonim hale getirilmektedir. İstatistik amacıyla kullanılan veriler halihazırda Kanunun düzenlemesine ve politikamızın kapsamın dâhil değildir. İşbu politikamızı Erişim Medya olarak Kanuna uygunluk kapsamında kişisel verileri korumak maksadıyla değiştirme hakkımız haizdir.</p>

                <p>Gizlilik politikası Erişim Medya’nın çözüm ortaklığı yaptığı gerçek ve tüzel kişilerin, müşterilerinin, çalışanlarının ve diğer tüm kişilerin herhangi bir yoldan elde edilen verilerinin korunmasını amaçlamaktır. Bu bağlamda amaçlanan hedefi gerçekleştirmek amacıyla çeşitli düzenlemeleri ihtiva etmektedir.</p>
            </div>

            <div class="policy-page-section">
                <h2>4. Kişisel Verilerin ve/veya Özel Nitelikli Kişisel Verilerin İşlenmesi İle İlgili Temel Prensipler</h2>

                <p>Kişisel verilerin ve/veya özel nitelikli kişisel verilerin işlenmesi ile ilgili temel prensiplerimiz aşağıdaki gibidir. Bu bağlamda Erişim Medya’nın açık rızaya dayanarak veya Kanuna uygunluk halleri kapsamında işlediği verilerde işbu ilkeler geçerli olacaktır.</p>

                <p><span class="policy-page-definition">Hukuka Uygun Olması:</span> Erişim Medya gerçek ve tüzel kişilerden kendisine gelen ve muhtelif yollardan topladığı kişisel verilerin ve/veya özel nitelikli kişisel verilerin kaynağını ve hukuka uygunluğunu sorgulamaktadır. Bu bağlamda verilerin hukuka uygun olarak elde edilmesi Erişim Medya için önem arz etmektedir.</p>

                <p><span class="policy-page-definition">Dürüstlük Kurallarına Uygun Olması:</span> Erişim Medya gerçek ve tüzel kişilerden kendisine gelen ve muhtelif yollardan topladığı kişisel verilerin ve/veya özel nitelikli kişisel verilerin kaynağını sorgulamaktadır. Bu bağlamda verilerin dürüstlük kuralları çerçevesinde elde edilmesi Erişim Medya için önem arz etmektedir.</p>

                <p><span class="policy-page-definition">İşlendikleri Amaçla Sınırlı, Ölçülü ve Bağlantılı Olması:</span> Erişim Medya muhtelif yollardan elde ettiği kişisel verileri ve/veya özel nitelikli kişisel verileri işlendikleri amaca uygun, işlenme amacıyla sınırlı, ölçülü ve hizmetin ifasının gerektirdiği ölçüde kullanmaktadır.</p>

                <p><span class="policy-page-definition">Kişisel Verilerin ve/veya Özel Nitelikli Kişisel Verilerin Doğru Olması:</span> Erişim Medya gerçek ve tüzel kişilerden kendisine gelen ve muhtelif yollardan topladığı kişisel verilerin ve/veya özel nitelikli kişisel verilerin yanlış bilgi içermemesine ve doğru olmasına önem vermektedir. Fakat Erişim Medya müşterilerinin veya temas halinde olduğu gerçek ve tüzel kişilerin beyan ettiği kişisel verilerin ve/veya özel nitelikli kişisel verilerin doğruluğunu araştırmak zorunda değildir. Zira bu husus hukuken ve çalışma prensiplerimiz doğrultusunda mümkün değildir.</p>

                <p><span class="policy-page-definition">Gerektiğinde Güncel Olması:</span> Erişim Medya muhtelif şekillerde elde ettiği kişisel verilerde ve/veya özel nitelikli kişisel verilerde değişiklik olmuşsa, söz konusu değişikliğin şirkete iletilmesine ve iletildiği takdirde verinin güncellenmesine önem vermektedir.</p>

                <p><span class="policy-page-definition">Belirli ve Meşru Amaçlar İçin İşlenmesi:</span> Erişim Medya kişisel verileri ve/veya özel nitelikli kişisel verileri veri sahibinin verdiği açık rıza veya kanunen öngörülen istisnalar çerçevesinde açık rıza almaksızın işlemektedir. Her verinin işlenme amacı belirli olup meşru olmayan hiçbir amaç için kişisel veri işleme faaliyeti yürütülmemektedir.</p>

                <p><span class="policy-page-definition">Kanunda Öngörülen ve/veya İşlendikleri Amaç İçin Gerekli Olacak Süre Kadar Muhafaza Edilmesi:</span> Erişim Medya elde ettiği kişisel verileri ve/veya özel nitelikli kişisel verileri ilgili kanunlarda öngörülen ve/veya işlendikleri amaç için gerekli olan süreler boyunca saklamaktadır. Söz konusu amaçlar son bulduğunda veriyi anonimleştirmekte, yok etmekte veya silmektedir.</p>


                

            </div>

            <div class="policy-page-section">
                <h2>5. Kişisel Verilerin Silinmesi, Yok Edilmesi ve Anonim Hale Getirilmesi</h2>

                <p>Kişisel verileriniz ve/veya özel nitelikli kişisel verileriniz Kanunda öngörülen zamanaşımı ve saklama sürelerin dolması, yargı süreçlerinin tamamlanması veya amaca uygun diğer gerekliliklerin son bulması ile silinir, yok edilir veya anonim hale getirilir. Silme, yok etme ve anonim hale getirme işlemleri ilgili veri sahibinin talebi üzerine veya Erişim Medya tarafından re’sen (kendiliğinden) yapılmaktadır.</p>

            </div>

            <div class="policy-page-section">
                <h2>6. Cimrilik İlkesi</h2>

                <p>Cimrilik ilkesi diğer adıyla azami tasarruf ilkesidir. Muhtelif yollardan ulaşan kişisel veriler ve/veya özel nitelikli kişisel veriler şirketimizin sistemine aktarılır. Söz konusu ilke uyarınca veriler, gerekli olduğu kadar sisteme işlenmektedir.</p>

                <p>Erişim Medya tarafından toplanacak veriler amaca uygun olarak belirlenmekte ve değişkenlik göstermektedir. Bu bağlamda veriler amaca uygun olarak toplanmakta ve amaçla paralel olmayan veriler toplanmamaktadır. Amacı dışındaki fazlalık veriler şirket sistemine kaydedilmez, silinir, yok edilir veya anonim hale getirilir. Fakat söz konusu veriler istatistikî amaçla kullanılabilir.</p>

            </div>

            <div class="policy-page-section">
                <h2>7. Verilerin Gizliliği ve Güvenliği</h2>

                <p>Erişim Medya olarak kişisel verilerinizin ve/veya özel nitelikli kişisel verilerinizin gizliliğine önem vermekteyiz. Bu bağlamda herhangi bir yoldan Şirketimize ulaşan kişisel verileriniz ve/veya özel nitelikli kişisel verileriniz gizlidir. Erişim Medya ticari faaliyetini yürüttüğü her aşamada söz konusu verilerin gizliliğine riayet etmektedir. Bu bağlamda işbu şirket gizlilik politikasına tam uyum sağlanmaktadır.</p>

                <p>Muhtelif yollardan toplanan kişisel verilerin ve/veya özel nitelikli kişisel verilerin yetkisiz kişilerin eline geçmemesi, veri sahibin haklarına zarar gelmemesi-mağdur olmaması ve verilerin korunabilmesi için gerekli teknik ve idari tedbirler alınmaktadır. Ek olarak hukuka uygunluk çerçevesinde kişisel verileri ve/veya özel nitelikli kişisel verileri paylaştığımız üçüncü kişilerden de gerekli olduğu durumlarda veri koruması talep edilmektedir. Yine yazılım programlarımız güncellenmekte ve devamlı olarak yenilenmektedir. Üst düzey koruma sağlanması için teknolojik açıdan gereklilikler yerine getirilmekte ve standartlara uygunluk sağlanmaktadır.</p>

            </div>

            <div class="policy-page-section">
                <h2>8. Verilerin Güncelliği</h2>

                <p>Erişim Medya bünyesinde güncellik ilkesi esastır. Zira muhtelif şekillerde elde edilen kişisel veriler ve/veya özel nitelikli kişisel veriler gerekli olduğu durumda ya da taleple güncellenebilmektedir. Güncellemeye ilişkin gerekli önlemler de Erişim Medya tarafından alınmaktadır.</p>

            </div>

            <div class="policy-page-section">
                <h2>9. Verilerin Doğruluğu</h2>

                <p>Beyan edilen kişisel verilerin ve/veya özel nitelikli kişisel verilerin doğruluğu ilkesi Erişim Medya tarafından benimsenmiştir. Erişim Medya müşterilerinin veya temas halinde olduğu gerçek ve tüzel kişilerin beyan ettiği kişisel verilerin ve/veya özel nitelikli kişisel verilerin doğruluğunu araştırmak zorunda değildir. Zira bu husus hukuken ve çalışma prensiplerimiz doğrultusunda mümkün değildir. Bu bağlamda beyan edilen verilerin doğru olduğundan bahisle işlemler yürütülmektedir.</p>

            </div>

            <div class="policy-page-section">
                <h2>10. Kişisel Verilerin ve/veya Özel Nitelikli Kişisel Verilerin İşlenme Amaçları</h2>

                <p>Kişisel verilerin ve/veya özel nitelikli kişisel verilerin işlenmesi Erişim Medya’ya ait Aydınlatma Metinlerindeki amaçlar paralelinde gerçekleştirilmektedir. Söz konusu Aydınlatma Metinleri işlenen kişisel veriye ve/veya özel nitelikli kişisel veriye göre değişkenlik gösterebilmektedir. Aydınlatma Metinlerindeki farklılığa paralel olarak kişisel verilerin ve/veya özel nitelikli kişisel verilerin işlenme amaçları da değişkenlik gösterebilmektedir.</p>

            </div>


            <div class="policy-page-section">
                <h2>11. Reklam Amacıyla Kişisel Verilerin ve/veya Özel Nitelikli Kişisel Verilerin İşlenmesi</h2>

                <p>Reklam amaçlı gönderilen elektronik iletilerde alıcıdan onay alınması gerekmektedir. Bu bağlamda ancak önceden onay alınan kişilere reklam amaçlı elektronik ileti gönderilebilir. Söz konusu husus “Elektronik Ticaretin Düzenlenmesi Hakkında Kanun” ile “Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik”’te de açıkça düzenlenmiştir.</p>

                <p>Erişim Medya reklam amaçlı elektronik ticari ileti gönderirken yukarıda bahsi geçen mevzuat düzenlemelerine uygun davranmaktadır. Yine mevzuata uygun olarak onay alınmasına ve onayın detaylarına riayet edilmektedir. Söz konusu onay; her türlü elektronik iletişim aracıyla veya yazılı olarak fiziki ortamda alınabilir. Onayda esas olan ticari elektronik ileti alıcısının, ticari elektronik ileti gönderilmesini kabul ettiğine dair olumlu irade beyanının varlığı, elektronik iletişim adresi ve ad-soyadının bulunmasıdır.</p>
                <p>Alıcıdan alınan onay, şirketin mal ve hizmetlerini pazarlamak, tanıtmak, işletmesini tanıtmak, tanınırlığını sağlamak, kutlama, temenni ve tebrik vb. içeriklerle tanınırlığını arttırmak amacıyla elektronik iletişim adreslerine gönderilen tüm ticari elektronik iletileri kapsamaktadır.</p>
                <p>Tüm bunların haricinde elektronik ticari ileti gönderilmeden önce kişiler verilerinin işlenebileceği konusunda aydınlatılmakta ve gerektiği durumda açık rızaları alınmaktadır. Bu ticari ileti gönderilirken de 6698 Sayılı Kişisel Verilerin Korunması Kanunu hükümlerine uygun davranılmaktadır.</p>

            </div>

            <div class="policy-page-section">
                <h2>12. Sözleşme İlişkisi İçerisinde Kişisel Verilerin ve/veya Özel Nitelikli Kişisel Verilerin Toplanması ve İşlenmesi</h2>

                <p>Müşteriler veya muhtemel müşterilerle sözleşme ilişkisi kurulmuş ise, sözleşme uyarınca toplanmış olan kişisel veriler açık rıza alınmaksızın Erişim Medya tarafından işlenebilir. Özel nitelikli kişisel verilerin işlenmesi söz konusu olduğu durumda açık rıza ya da Kanun’un 6. Maddesi kapsamındaki hukuki sebeplere dayanılarak veri işlenebilmektedir. Söz konusu veriler mal ve/veya hizmetin ifası, sözleşmenin icrası, ticari faaliyetin yürütümü kullanılmaktadır. İşbu veriler müşterilerle iletişime geçerek her zaman güncellenebilir.</p>

            </div>

            <div class="policy-page-section">
                <h2>13. Otomatik Sistemler Vasıtasıyla İşlenen Kişisel Veriler ve/veya Özel Nitelikli Kişisel Veriler</h2>

                <p>Otomatik sistemler aracılığıyla kişilerin açık rızası olmaksızın elde edilen bilgilerden edinilen veriler kişilerin aleyhine kullanılamaz. Erişim Medya otomatik sistemler vasıtasıyla işlenen kişisel verilerde ve/veya özel nitelikli kişisel verilerde ilgili mevzuat hükümlerine uygun davranmaktadır.</p>
            </div>

            <div class="policy-page-section">
                <h2>14. Erişim Medya’nın Çalışanlarına Ait Kişisel Veriler Ve/Veya Özel Nitelikli Kişisel Veriler</h2>

                <p><span class="policy-page-definition">Hukuki Yükümlülükler Çerçevesinde Yapılan İşleme:</span> Çalışanlara ait kişisel Veriler, verinin işlenmesine ilişkin ilgili Kanunda açıkça hüküm bulunması veya veri sorumlusunun hukuki yükümlülüğünün yerine getirilmesi maksadıyla açık rızası alınmaksızın Erişim Medya tarafından işlenebilir.</p>

                <p><span class="policy-page-definition">İş Sözleşmesi ve İş İlişkisi Uyarınca Kişisel Verilerin İşlenmesi:</span> Çalışanların kişisel verileri şirketle çalışanların iş ilişkisinin sağlanabilmesi için gerekli olduğu kadar ölçülülük çerçevesinde çalışanların açık rızası alınmaksızın işlenebilmektedir. Erişim Medya her koşulda çalışanlara ait verilerin korunmasını, gizliliğini ve bu hususta gereken tedbirleri alacağını taahhüt etmektedir.</p>

                <p><span class="policy-page-definition">Çalışanlara Ait Özel Nitelikli Kişisel Verilerin İşlenmesi:</span> 6698 Sayılı Kişisel Verilerin Korunması Kanunu uyarınca özel nitelikli kişisel verilerin işlenmesi için verisi işlenecek kişinin açık rızası ve ek olarak Kurul tarafından öngörülen gerekli önlemlerin alınması gerekmektedir. Erişim Medya 6698 Sayılı Kanun’a ve Kurulun esaslarına uygunluk çerçevesinde özel nitelikli kişisel verileri işlerken gerekli olduğu durumda hem ilgilinin açık rızasını hem de Kurul’un belirlediği gereken önlemleri almaktadır. Ancak özel nitelikli kişisel veriler Kanunda öngörülen istisnai hallerle ilgili kişinin açık rızası olmaksızın sınırlı ve ölçülü olmak koşuluyla işlenebilir.</p>

                <p><span class="policy-page-definition">Otomatik Sistemler Vasıtasıyla İşlenen Kişisel Veriler:</span> Çalışanların otomatik sistemlerde bazı kişisel verileri işlenebilmektedir. Söz konusu veriler çalışanların performans değerlendirmesinde, istatistiki verilerin tutulmasında, şirket için terfilerde ve puanlamalarda kullanılmaktadır. Çalışanların kendilerine ilişkin ortaya çıkan aleyhe sonuçlara itiraz hakkı bulunmaktadır. İtiraz, şirket içi kurallara ve prosedüre uygun olarak gerçekleştirilmelidir. Söz konusu itiraz şirket içinde değerlendirmeye tabi tutulmaktadır.</p>

                <p><span class="policy-page-definition">Kişisel Verilerin Çalışanların Yararına İşlenmesi:</span> Çalışanlara ait kişisel veriler, çalışanın menfaatine ilişkin işlemler için iş sözleşmesinin sağladığı ilişki çerçevesinde açık rıza alınmadan Erişim Medya tarafından işlenebilmektedir. Yine Erişim Medya ile iş ilişkisine ilişkin ihtilaflar içinde, Şirket çalışanlara ait kişisel verileri işleyebilir.</p>

                <p><span class="policy-page-definition">Şirket İçi Telekomünikasyon, İnternet ve İletişim:</span> İşin ifasının daha kolay sağlanabilmesi adına Erişim Medya tarafından şirket içi çalışanlara bilgisayar, telefon, araba, uygulamalar, yazılımlar ve e-posta tahsis edilebilmektedir. Erişim Medya tahsis ettiği araçlar üzerindeki kişisel verileri kontrol edebilir ve denetleyebilir. Fakat bu noktada Erişim Medya gerekli Aydınlatma Yükümlülüğünü de yerine getirmektedir. Çalışan ise kendisine tahsis edilen araçları özel amaçları için kullanamaz. Sadece işin ifasını sağlamak maksadı ile kullanması zorunludur. Yine çalışan Erişim Medya ile çalışma ilişkisinin başladığı andan itibaren tahsis edilen araçlarda iş ve işin gerekliliği dışında hiçbir veri, bilgi bulundurmayacağını kabul, beyan ve taahhüt etmektedir.</p>


            </div>

            <div class="policy-page-section">
                <h2>15. Kişisel Verilerin ve/veya Özel Nitelikli Kişisel Verilerin Yurt İçi Ve Yurt Dışına Aktarılması</h2>

                <p>Erişim Medya, Kişisel Verilerin Korunması Kanununda öngörülen şartlara uygun olarak ve Kurul’un belirlediği esaslar çerçevesinde kanuna uygunluk halleri kapsamında veyahut ilgili kişinin açık rızası ile yurt içi ve yurt dışına veri aktarımı yapabilmektedir.</p>
                <p>Erişim Medya, kişisel verileri ve/veya özel nitelikli kişisel verileri aktarırken 6698 Sayılı Kanun’un 8. ve 9. maddelerine uygun davranmaktadır.</p>
            </div>

            <div class="policy-page-section">
                <h2>16. Denetim ve İşlem Güvenliği</h2>

                <p>Muhtelif yollardan toplanan kişisel verilerin ve/veya özel nitelikli kişisel verilerin yetkisiz kişilerin eline geçmemesi, veri sahibin haklarına zarar gelmemesi-mağdur olmaması ve verilerin korunabilmesi için gerekli teknik ve idari tedbirler alınmaktadır. Ek olarak hukuka uygunluk çerçevesinde kişisel verileri ve/veya özel nitelikli kişisel verileri paylaştığımız şirketlerden de gerektiği durumda veri koruması talep edilmektedir. Yine yazılım programlarımız güncellenmekte, devamlı olarak yenilenmekte ve geliştirilmektedir. Üst düzey koruma sağlanması için teknolojik açıdan tüm gereklilikler yerine getirilmektedir ve standartlara uygunluk sağlanmaktadır. Tüm bunlara paralel olarak Erişim Medya kişisel verilerin ve/veya özel nitelikli kişisel verilerin korunabilmesi için gereken tüm iç ve dış denetimleri yaptırmaktadır.</p>
            </div>

            <div class="policy-page-section">
                <h2>17. Veri İhlallerinin Bildirimi</h2>

                <p>Verilerle ilgili herhangi bir ihlal durumu söz konusu olduğunda, Erişim Medya kendisine bildirilen ihlalleri gidermek için derhal üzerine düşeni yapmak için harekete geçer. İlgilinin zararını en aza indirebilmek için gereken tedbirleri alır. İhlallerin bildirimi için şirketimizin internet sitesinde belirtilen usullere göre başvuruda bulunabilirsiniz. Ek olarak kişisel verilerin ve/veya özel nitelikli kişisel verilerin dışarından yetkisiz üçüncü kişilerin eline geçmesi durumunda şirket söz konusu hususu direk Kişisel Verileri Koruma Kurulu’na bildirmektedir.</p>
            </div>

            <div class="policy-page-section">
                <h2>18. Bilgi Talep Eden Veri Sahibi İlgili Kişinin Hakları</h2>

                <p>6698 Sayılı Kişisel Verilerin Korunması Kanununun 11. maddesinde veri sahibinin hakları hüküm altına alınmıştır. Erişim Medya kanun uyarınca veri işlemeden önce ilgili kişinin aydınlatılmasını ve gerektiği durumda açık rızasının alınmasını, veri işlendikten sonra ilgili kişinin verisi hakkında bilgi talep etme, güncelleme, silme, yok etme ve anonim hale getirme hakkı olduğunu kabul etmektedir.</p>
                <p>İlgili kişiler “6698 Sayılı Kişisel Verilerin Korunması Kanunu Uyarınca İletişim” vasıtasıyla verileriyle ilgili;</p>

                <ul>
                    <li>Kişisel veri işlenip işlenmediğini öğrenme</li>
                    <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme</li>
                    <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme</li>
                    <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme</li>
                    <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme</li>
                    <li>“Kişisel verilerin silinmesi, yok edilmesi veya anonim hâle getirilmesi” başlıklı kanunun 7. Maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme</li>
                    <li>Eksik ya da yanlış işlenmiş verinin düzeltildiğini veya ilgilinin talebi üzerine verinin silindiğini veyahut yok edildiğine ilişkin bilginin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme</li>
                    <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme</li>
                    <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme</li>
                </ul>
                <p>Haklarına sahiptir.</p>
                <p>Başkası adına yapılan bilgi talebi de şirket tarafından cevaplanmayacaktır. Bilgi talebinde bir başkası adına yapılan başvuru yapıldığı Erişim Medya tarafından tespit edilirse; Erişim Medya’nın her türlü dava ve talep hakkı saklıdır. İlgili kişinin talepleri Erişim Medya’ya ulaştığı tarihten itibaren mümkün olan en kısa sürede ve geç otuz gün içerisinde cevaplanacaktır. Erişim Medya gerekli görürse başvuru yapan ilgili kişiden başka bilgi ve belge talep edebilecektir.</p>
            </div>

            <div class="policy-page-section">
                <h2>19. Güncelleme</h2>

                <p>İşbu Gizlilik Politikasında yapılan değişiklikler aşağıdaki tabloda listelenmekte ve gösterilmektedir.</p>

                <p><span class="policy-page-definition">Politika Güncelleme Tarihi:</span> 02.08.2021</p>

                <p><span class="policy-page-definition">Değişiklikler:</span> Verilerin Güncelliği ilkesi güncellendi.</p>
            </div>
<br />
            <span class="policy-page-definition ">ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş.</span>



        </div>
    )
}

export default PrivacyPolicy