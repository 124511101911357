import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./sgbottomcarousel.css";

const API_BASE_URL = "https://api.sigortagundem.com";
const API_URL = `${API_BASE_URL}/under-headline-components`;

const SgBottomCarousel = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const carouselRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(API_URL)
      .then((response) => {
        const filteredPosts = response.data
          .filter(
            (item) => item.isDraft === false 
          )
          .sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate))
          .slice(0, 8);

        setNewsItems(filteredPosts);
      })
      .catch((error) => {
        console.error("Error fetching left headlines news:", error);
      });
  }, []);

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex < 5 ? prevIndex + 1 : 0));
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : newsItems.length - 1
    );
  };

  useEffect(() => {
    const carousel = carouselRef.current;

    if (carousel) {
      const itemWidth = carousel.clientWidth / 3;
      carousel.style.transition = "transform 0.5s ease";
      carousel.style.transform = `translateX(-${startIndex * itemWidth}px)`;
    }
  }, [startIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval);
  }, [startIndex, newsItems.length]);

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      // Increment view count
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

      // Navigate to news article
      navigate(`/${slug}`);
    } catch (error) {
      console.error("Error incrementing view count:", error);
      // Navigate to news article even if there's an error
      navigate(`/${slug}`);
    }
  };

  return (
    <div className="carousel sg-bottom-carousel">
      <button className="sg-bottom-carousel-button" onClick={handlePrev}>
        <span class="material-symbols-outlined">
chevron_left
</span>
      </button>
      <div className="sg-bottom-carousel-inner-wrapper">
        <div className="sg-bottom-carousel-inner" ref={carouselRef}>
          {newsItems.map((item, index) => (
            <div
              key={index}
              className="carousel-item sg-bottom-carousel-item cursor-pointer"
              style={{ backgroundImage: `url(${item.image})` }}
              onClick={(e) => handleNewsClick(item._id, item.slug, e)}
            >
              <h3 className="carousel-item-title">{item.title}</h3>
            </div>
          ))}
        </div>
      </div>
      <button className="sg-bottom-carousel-button" onClick={handleNext}>
        <span class="material-symbols-outlined">
chevron_right
</span>
      </button>
    </div>
  );
};

export default SgBottomCarousel;
